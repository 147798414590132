@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Manrope:wght@600&family=Rubik&family=Suez+One&display=swap');


:root {
  --primary: #3465a4;
  --secondary: #00e1f5;
  --accent: #ffff00;
}

.App {
  font-family: 'Suez One', serif;
  text-align: center;

}

input[type="text"] {
  font-family: 'Manrope', sans-serif;
}

input[type="file"] {
  border: none;
  
}

span {
  color: red;
}

li {
  list-style-type: none;
}

hr {
  height: 5px;
  border-width: 0;
  color: rgb(0, 0, 0);
  background-color: rgb(0, 0, 0)
}

a {
  text-decoration: none;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.me-auto {
  margin-left: 9rem;
}

.navbar {
  font-family: 'Rubik', sans-serif;
  background-color: var(--primary);
}

.nav-logo-container {
  height: 3rem;
  width: 10rem;
}



#nav-logo {
  margin-top: -1rem;
  width: 10rem;
  overflow: hidden;

}

.map-container {
  width: 100vw;
  height: 50vh;
  display: flex;

}

.card {
  margin-top: 15vh;
  border: 1px solid black;
  width: 40rem;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.delete-card {
  margin-top: 15vh;
  border: 1px solid black;
  width: 98%;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.input-field {
  width: 100%;
  height: 3rem;
  border: 1px solid black;
  border-radius: 30px;
  padding: 12px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.submit-button {
  background-color: var(--primary);
  color: var(--accent);
  border: 1px solid black;
  border-radius: 30px;
  padding: 1rem;
  width: 100%;
}

.submit-button:hover {
  border-color: var(--accent);
  transform: scale(1.1);
}

.delete-button {
  background-color: red;
  color: black;
  border: 1px solid black;
  border-radius: 30px;
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.delete-button:hover {
  border-color: var(--accent);
  transform: scale(1.1);
}

.search-results {
  margin-top: 2rem;
  background: white;
  padding: 2rem;
}

.results-display {
  font-size: 1.3rem;
  background-color: var(--secondary);
  width: 100%;
  margin: auto;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.results-display a {

  text-decoration: none;
  color: black;
}

.results-display:hover {
  transform: scale(1.1);
}

.details-card {
  margin-top: 8vh;
  width: 100vw;
  padding: 2rem;
}

.locations-list {
  
  background-color: var(--primary);
  padding-top: 2rem;
  height: 100%;
  width: 100vw;
  color: black;
}

.admin-buttons button {
  margin-top: 2vh;
}

.store-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 99vw;
  max-width: fit-content;
  margin-top: 5rem;
  padding: 1rem;
  padding-top: 3rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.store-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.store-div {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 259px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 10px;
  font-family: SQ Market, SQ Market, Helvetica, Arial, sans-serif;
  margin: 2rem;
  
}

.store-img {
  width: 100%;
  height: 100%;
}

.store-inner-div {
  padding: 13px;
}

.store-p {
  font-size: .9rem;
  line-height: 20px;
  font-weight: 600;
}

.store-link {
  display: inline-block;
  font-size: 18px;
  line-height: 48px;
  height: 48px;
  color: var(--secondary);
  min-width: 212px;
  background-color: var(--primary);
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1) inset;
  border-radius: 6px;
  text-decoration: none
}

.store-link:hover {
  transform: scale(1.1);
  color: var(--accent);
}

.ad-banner {
  background-color: #f1e98f;
  padding: 1.4rem;
  border-radius: 3rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.nav-text {
  transition: all 0.8s;
}

.nav-text:hover {
  transform: scale(1.5) rotate(10deg);
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
  background-color: var(--primary);
  width: 100vw;
  height: 7rem;
  padding: 3px;
}

i {
  font-size: 2rem;
  color: var(--accent);
}

.socials i {
  margin-top: 1rem;
}

footer li {
  display: inline;
  margin-right: 2rem;
}

i:hover{
  transform: scale(1.1) rotate(-9deg);
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
}

.copyright p {
  margin-top: 1rem;
}

.copyright i {
  margin-left: 1rem;
}

.stupid-img {
  margin-bottom: 10px;
}

.stupid-img:hover{
  transform: scale(1.1) rotate(-9deg);
}